import "./App.css";
import { useState, useEffect } from "react";

function App() {
  const [date, setDate] = useState(new Date());

  function refreshClock() {
    setDate(new Date());
  }

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);
  return (
    <>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@500;600&display=swap');
      </style>

      <div className="App">
        <div className="main">
          <div>
            <span class="hour_minute">
              {date.getHours().toString().padStart(2, "0")}:
              {date.getMinutes().toString().padStart(2, "0")}
            </span>
            <span className="second">
              :{date.getSeconds().toString().padStart(2, "0")}
            </span>
          </div>
          <div>
            <span className="date">{date.toLocaleDateString("en-AU")}</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
